import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "modal-wrapper" }
const _hoisted_2 = { class: "modal-container" }
const _hoisted_3 = { class: "modal-header" }
const _hoisted_4 = { class: "header-close" }
const _hoisted_5 = { class: "modal-body" }
const _hoisted_6 = { class: "modal-footer" }



export default /*@__PURE__*/_defineComponent({
  __name: 'Modal',
  props: {
  showModal: Boolean,
  cssClass: String,
},
  setup(__props) {

const props = __props;

return (_ctx: any,_cache: any) => {
  return (props.showModal)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["lmsmodal", props.cssClass])
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("h3", null, [
                _renderSlot(_ctx.$slots, "header")
              ]),
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("span", {
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
                }, _cache[1] || (_cache[1] = [
                  _createElementVNode("i", { class: "icon icon-cross" }, null, -1)
                ]))
              ])
            ]),
            _createElementVNode("div", _hoisted_5, [
              _renderSlot(_ctx.$slots, "body")
            ]),
            _createElementVNode("div", _hoisted_6, [
              _renderSlot(_ctx.$slots, "footer")
            ])
          ])
        ])
      ], 2))
    : _createCommentVNode("", true)
}
}

})