<script lang="ts" setup>
import { defineProps } from "vue";
const props = defineProps({
  showModal: Boolean,
  cssClass: String,
});
</script>

<template>
  <div v-if="props.showModal" class="lmsmodal" :class="props.cssClass">
    <div class="modal-wrapper">
      <div class="modal-container">
        <div class="modal-header">
          <h3><slot name="header"> </slot></h3>
          <div class="header-close">
            <span @click="$emit('close')">
              <i class="icon icon-cross"></i>
            </span>
          </div>
        </div>

        <div class="modal-body">
          <slot name="body"> </slot>
        </div>

        <div class="modal-footer">
          <slot name="footer"> </slot>
        </div>
      </div>
    </div>
  </div>
</template>
