import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import moment from "moment";
import { createPinia } from "pinia";
import LmsModal from "./components/module/Modal.vue";
import "vue3-easy-data-table/dist/style.css";

import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

import Vue3VideoPlayer from "@cloudgeek/vue3-video-player";
import "@cloudgeek/vue3-video-player/dist/vue3-video-player.css";

import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import Vue3Toastify, { type ToastContainerOptions } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

import VuePdf from "vue3-pdfjs";

const app = createApp(App);

/**
 * @params {date} date to be converted to timeago
 * @returns returns timeAgo
 */
app.config.globalProperties.$filters = {
  timeAgo(date) {
    return moment(date).fromNow();
  },
};

//const app = createApp(App);
app.component("LmsModal", LmsModal);
app.component("QuillEditor", QuillEditor);

const options = {
  confirmButtonColor: "#41b882",
  cancelButtonColor: "#ff7674",
};
app.use(Vue3VideoPlayer, {
  lang: "en",
});
app.use(Vue3Toastify, {
  autoClose: 3000,
} as ToastContainerOptions);
app.use(VuePdf);
app.use(VueSweetalert2, options);
app.use(createPinia());
app.use(store).use(router).mount("#app");
