import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("../views/home.vue"),
    meta: { template: "admin" },
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/login.vue"),
    meta: { template: "noside" },
  },
  {
    path: "/logout",
    name: "Logout",
    component: () => import("../views/logout.vue"),
    meta: { template: "noside" },
  },
  {
    path: "/ResetPassword",
    name: "ResetPassword",
    component: () => import("../views/Account/ResetPassword.vue"),
    meta: { template: "noside" },
  },
  {
    path: "/ForgetPassword",
    name: "ForgetPassword",
    component: () => import("../views/Account/ForgetPassword.vue"),
    meta: { template: "noside" },
  },
  {
    path: "/MyAccount",
    name: "MyAccount",
    component: () => import("../views/Account/MyAccount.vue"),
    meta: { template: "admin" },
  },
  {
    path: "/MyGroups/",
    name: "MyGroups",
    component: () => import("../views/Account/MyGroups.vue"),
    meta: { template: "admin" },
  },
  {
    path: "/MyClassrooms/",
    name: "MyClassrooms",
    component: () => import("../views/Account/MyClassrooms.vue"),
    meta: { template: "admin" },
  },
  {
    path: "/MyEducations",
    name: "MyEducations",
    component: () => import("../views/Account/MyEducations.vue"),
    meta: { template: "admin" },
  },
  {
    path: "/EducationList",
    name: "EducationList",
    component: () => import("../views/Education/MyEducations.vue"),
    meta: { template: "admin" },
  },
  {
    path: "/Education/:id",
    name: "Education",
    component: () => import("../views/Education/Education.vue"),
    meta: { template: "panel" },
  },
  {
    path: "/DoEducation/:id",
    name: "DoEducation",
    component: () => import("../views/Education/DoEducation.vue"),
    meta: { template: "panel" },
  },
  {
    path: "/EditEducation/:id",
    name: "EditEducation",
    component: () => import("../views/Education/EditEducation.vue"),
    meta: { template: "admin" },
  },
  {
    path: "/NotStartEducations",
    name: "NotStartEducations",
    component: () => import("../views/Education/NotStartEducations.vue"),
    meta: { template: "panel" },
  },
  {
    path: "/UnfinishedEducations",
    name: "UnfinishedEducations",
    component: () => import("../views/Education/UnfinishedEducations.vue"),
    meta: { template: "panel" },
  },
  {
    path: "/CompletedEducations",
    name: "CompletedEducations",
    component: () => import("../views/Education/CompletedEducations.vue"),
    meta: { template: "panel" },
  },
  {
    path: "/manage",
    name: "ManageHome",
    component: () => import("../views/Manage/ManageHome.vue"),
    meta: { template: "panel" },
  },
  {
    path: "/categories",
    name: "Categories",
    component: () => import("../views/Manage/Categories.vue"),
    meta: { template: "panel" },
  },
  {
    path: "/groups",
    name: "Groups",
    component: () => import("../views/Manage/Groups.vue"),
    meta: { template: "panel" },
  },
  {
    path: "/brands",
    name: "Brands",
    component: () => import("../views/Manage/Brands.vue"),
    meta: { template: "panel" },
  },
  {
    path: "/brandgroups/:id",
    name: "BrandGroups",
    component: () => import("../views/Manage/BrandGroups.vue"),
    meta: { template: "panel" },
  },
  {
    path: "/brandclassrooms/:id",
    name: "BrandClassrooms",
    component: () => import("../views/Manage/BrandClassrooms.vue"),
    meta: { template: "panel" },
  },
  {
    path: "/brandeducations/:id",
    name: "BrandEducations",
    component: () => import("../views/Manage/BrandEducations.vue"),
    meta: { template: "panel" },
  },
  {
    path: "/classrooms",
    name: "Classrooms",
    component: () => import("../views/Manage/Classrooms.vue"),
    meta: { template: "panel" },
  },
  {
    path: "/educations",
    name: "Educations",
    component: () => import("../views/Manage/Educations.vue"),
    meta: { template: "panel" },
  },
  {
    path: "/users",
    name: "Users",
    component: () => import("../views/Manage/Users.vue"),
    meta: { template: "panel" },
  },
  {
    path: "/userGroups/:id",
    name: "UserGroups",
    component: () => import("../views/Manage/UserGroups.vue"),
    meta: { template: "panel" },
  },
  {
    path: "/userClassrooms/:id",
    name: "UserClassrooms",
    component: () => import("../views/Manage/UserClassrooms.vue"),
    meta: { template: "panel" },
  },
  {
    path: "/userEducations/:id",
    name: "UserEducations",
    component: () => import("../views/Manage/UserEducations.vue"),
    meta: { template: "panel" },
  },
  {
    path: "/EducationReports",
    name: "EducationReports",
    component: () => import("../views/Report/Educations.vue"),
    meta: { template: "panel" },
  },
  {
    path: "/UserReports",
    name: "UserReports",
    component: () => import("../views/Report/Users.vue"),
    meta: { template: "panel" },
  },
  {
    path: "/UserReport/:id",
    name: "UserReport",
    component: () => import("../views/Report/UserReport.vue"),
    meta: { template: "panel" },
  },
  {
    path: "/EducationReport/:id",
    name: "EducationReport",
    component: () => import("../views/Report/EducationReport.vue"),
    meta: { template: "panel" },
  },
  {
    path: "/EducationUserReport/:id",
    name: "EducationUserReport",
    component: () => import("../views/Report/EducationUserReport.vue"),
    meta: { template: "panel" },
  },
  {
    path: "/Objections/",
    name: "Objections",
    component: () => import("../views/Objections/List.vue"),
    meta: { template: "panel" },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const publicPages = [
    "/login",
    "/register",
    "/resetpassword",
    "/forgetpassword",
  ];
  const authRequired = !publicPages.includes(to.path.toLowerCase());
  const loggedIn = localStorage.getItem("user");

  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !loggedIn) {
    next("/login");
  } else {
    next();
  }
});

export default router;
